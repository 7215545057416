@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Comfortaa', cursive;
}

/**/

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container
{
    padding: 2rem;
    color: white !important;
}

.logo img
{
    width: 215px;
}

.title
{
    font-size: 1.3rem;
    margin-top:2rem;
    color:#7b7fbe;
}
.subtitle
{
    margin-top:0.5rem;
    margin-bottom:1.5rem;
}

/* Location Box */
.location-box
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.form-piece
{
    flex:1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form-label
{
    margin-right: 0.5rem;
    flex:1;
}

.form-input
{
    margin-right: 0.5rem;
    padding: 0.5rem;
    flex:2

}

.form-buttons
{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    gap: 0.5rem;
}

.form-button
{
    background: #7b7fbe;
    border-color: #7c81c7;
    color: #fff;
    padding: 0.7rem;
    font-size: 0.9rem;
    cursor: pointer;
    flex: 1 1;
    text-decoration: none;
}

.location-info
{
    margin-top: 2rem;
    margin-bottom: 1rem;
}

/* Current KP */
.current-kp-data
{
    width: 100%;
    margin-top:2rem;
}

.current-kp-data .boxes
{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.current-kp-data .kp-title
{
    font-size: 1.3rem;
    color:#7b7fbe;
}

.current-kp-data .left-box
{
    text-align: center;
    padding: 2rem;
}
.kp-round-bg
{
    min-width: 50px;
    min-height: 50px;
    background-color: beige;
    border-radius: 200px;
}
.bg-green
{
    background-color: green;
}
.bg-yellow
{
    background-color: yellow;
    color:black;
}
.bg-orange
{
    background-color: orange;
    color:black;
}
.bg-red
{
    background-color: red;
}

/* Forecast KP + Weather */
.forecast-container
{
    margin-top:0.8rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.forecast-kp-data
{
    width:100%;
}

.forecast-kp-data .kp-title
{
    font-size: 1.3rem;
    color:#7b7fbe;
    margin-bottom:0.5rem;
}

.forecast-weather-data
{
    width: 100%;
}
.forecast-weather-data .kp-title
{
    margin-top: 1rem;
    font-size: 1.3rem;
    color:#7b7fbe;
}

.no-forecast-available
{
    margin-top:2rem;
    font-size: 0.8rem;
    text-align: center;
}

/* Weather */

.weather-item
{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top:1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #7b7fbe;
}
.weather-item .forecast-title
{
    flex:2 2 25%;
    font-weight: bold;
}
.weather-item .forecast-image
{
    flex: 2 2 25%;
    text-align: center;
}
.forecast-image img
{
    aspect-ratio: 1/1;
    max-width: 80px;
}
.weather-item .forecast-main
{
    flex: 3 3 35%;
}
.forecast-main .main-subtitle
{
    font-size: 0.7rem;
}

/* ForecastKpWeather */
.weather-item .forecast-kp
{
    text-align: center;
    margin: auto;
    font-size: 1.5rem;
    line-height: 3.1rem;
    flex:1 1 10%;
}
.weather-item .forecast-temperature
{
    flex:2 2 33%;
    text-align: center;
}
.weather-item .forecast-rain
{
    flex:2 2 33%;
    text-align: center;
}
.weather-item .forecast-wind
{
    flex:2 2 33%;
    text-align: center;
}

/* Mappa */
.mappa
{
    width: 100%;
    height: 75vh;
}
.map-title
{
    font-size: 1.3rem;
    color:#7b7fbe;
    padding:1rem;
}
.map-subtitle
{
    color:white;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.location-button
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap:1rem;
    align-items: center;
    margin-top:1rem;
    margin-bottom:1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.location-button .button-back
{
    flex: 1 1 50%;
    color:#7b7fbe;
    background-color: white;
    padding: 0.5rem;
    text-align: center;
    text-decoration: none;
}
.location-button .button-done
{
    flex: 1 1 50%;
    color: white;
    background-color: #7b7fbe;
    padding: 0.5rem;
    text-align: center;
    text-decoration: none;
}

/* Shadow mappa */
.leaflet-pane.leaflet-shadow-pane
{
    display: none;
}

/* Responsive */
@media (max-width: 420px){ 

    .weather-item .forecast-title
    {
        flex:2 2 22%;
        font-weight: bold;
        font-size: 0.7rem;
    }
    .weather-item .forecast-image
    {
        flex: 2 2 10%;
        text-align: center;
    }
    .forecast-image img
    {
        aspect-ratio: 1/1;
        max-width: 70px;
    }
    .weather-item .forecast-main
    {
        flex: 3 3 30%;
    }
    .forecast-main .main-subtitle
    {
        font-size: 0.7rem;
    }

    .weather-item .forecast-kp
    {
        text-align: center;
        margin: auto;
        font-size: 1.4rem;
        line-height: 3.1rem;
        flex:1 1 5%;
    }

    .form-button
    {
        font-size: 0.9rem;
    }

    .mappa
    {
        width: 100%;
        height: 70vh;
    }

}


/* Large screens */
@media (min-width: 82rem){ 

    .form-buttons
    {
        margin-top: 0;
        width: auto;
        flex: 1 1 20%;
    }

    .weather-item .forecast-title
    {
        flex:2;
    }
    .weather-item .forecast-image
    {
        flex:2;
    }
    .weather-item .forecast-main
    {
        flex:3;
    }
    .weather-item .forecast-kp
    {
        flex:1;
    }
    .weather-item .forecast-temperature
    {
        flex:2;
    }
    .weather-item .forecast-rain
    {
        flex:2;
    }
    .weather-item .forecast-wind
    {
        flex:2;
    }

    .no-forecast-available
    {
        padding-left: 1rem;
        text-align: left;
    }

}